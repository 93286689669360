<template>
  <v-container id="regular-tables" fluid tag="section">
    <v-card elevation="4" class="mt-0">
      <v-data-table
        v-if="listAttributes"
        :headers="headers"
        :items="listAttributes"
        :loading="isLoadingAttributes"
        :items-per-page="30"
        :footer-props="{ 'items-per-page-options': [10, 30, 50] }"
      >
        <template v-slot:item.values="{ item }">
          <v-chip v-for="value in item.values" :key="value.id">
            {{ value.name }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="edit(item)"> mdi-pencil </v-icon>
          <v-icon small @click="destroy(item)"> mdi-delete </v-icon>
        </template>
        <template v-slot:no-data>
          {{ $t("no_data_available") }}
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("attribute") }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-row justify="end">
              <v-dialog v-model="isVisibleDialog" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="primary" v-bind="attrs" v-on="on">
                    {{ $t("add_new_attribute") }}
                  </v-btn>
                </template>
                <v-form ref="form" v-model="is_valid_form" lazy-validation>
                  <v-card>
                    <v-card-title class="pb-4">
                      <span class="text-h3">{{ $t("attribute") }}</span>
                    </v-card-title>
                    <v-card-text class="">
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="5" md="5">
                            <v-text-field
                              dense
                              auto-select-first
                              hide-details
                              outlined
                              :label="$t('name_*')"
                              v-model="editedAttribute.name"
                              :rules="[rules.required]"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="7" md="7">
                            <v-combobox
                              clearable
                              dense
                              hide-details
                              outlined
                              multiple
                              small-chips
                              v-model="editedAttribute.values"
                              :label="$t('values')"
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <small>{{ $t("indicates_required_field") }}</small>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" text @click="closeForm()">
                        {{ $t("close") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="save(editedAttribute)"
                        :disabled="!is_valid_form"
                      >
                        {{ $t("save") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-dialog>
              <v-dialog v-model="isVisibleDeleteDialog" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">
                    {{ $t("delete_confirmation_message") }}
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeForm">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      @click="destroyConfirm(editedAttribute)"
                      >{{ $t("ok") }}</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { validationRules } from "@/mixins/validationRules";

export default {
  mixins: [validationRules],

  mounted() {
    this.$store.dispatch("attributes/list");
  },

  computed: {
    ...mapGetters({
      isLoadingAttributes: "attributes/isLoadingAttributes",
      listAttributes: "attributes/listAttributes",
      listStatuses: "statuses/listStatuses",
    }),
  },
  data() {
    return {
      is_valid_form: true,
      isVisibleDialog: false,
      isVisibleDeleteDialog: false,
      editedAttribute: {},
      headers: [
        {
          text: this.$t("name"),
          align: "start",
          value: "name",
        },
        {
          text: this.$t("values"),
          align: "center",
          value: "values",
        },
        {
          text: this.$t("actions"),
          align: "end",
          sortable: false,
          value: "actions",
          width: "15%",
        },
      ],
    };
  },

  methods: {
    edit(item) {
      this.isVisibleDialog = true;
      this.editedAttribute = Object.assign({}, item);
    },

    async save(item) {
      if (this.$refs.form.validate()) {
        try {
          if (item.id) {
            await this.$store.dispatch("attributes/update", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("updated_successfully")
            );
          } else {
            await this.$store.dispatch("attributes/add", item);
            this.$store.dispatch(
              "alerts/success",
              this.$t("created_successfully")
            );
          }
          this.$refs.form.reset();
          this.closeForm();
        } catch (error) {
          this.$store.dispatch("alerts/error", error.response?.data?.message);
        }
      }
    },

    destroy(item) {
      this.isVisibleDeleteDialog = true;
      this.editedAttribute = Object.assign({}, item);
    },

    async destroyConfirm(item) {
      try {
        await this.$store.dispatch("attributes/destroy", item);
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        this.closeForm();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },

    closeForm() {
      this.isVisibleDialog = false;
      this.isVisibleDeleteDialog = false;
      this.editedAttribute = {};
    },

    getColor(status) {
      if (status === "active") return "green";
      else return "red";
    },
  },
};
</script>
